const config = {
  authToken: "9e1f0d4f-d150-473b-8fc5-5adb7be3f0d3",
  swatches: {
    exterior: [
      {
        src:
          "https://solutions-engineering.s3.amazonaws.com/quickdemo/honda/swatches/exterior/obsidian-blue-pearl.png",
        config: "Blue Pearl",
        title: "Obsidian Blue Pearl",
      },
      {
        src:
          "https://solutions-engineering.s3.amazonaws.com/quickdemo/honda/swatches/exterior/blue-metallic.png",
        config: "Blue Metallic",
        title: "Blue Metallic",
      },
      {
        src:
          "https://solutions-engineering.s3.amazonaws.com/quickdemo/honda/swatches/exterior/radiant-red-metallic.png",
        config: "Red Metallic",
        title: "Radiant Red Metallic",
      },
      {
        src:
          "https://solutions-engineering.s3.amazonaws.com/quickdemo/honda/swatches/exterior/crystal-black-pearl.png",
        config: "Black Pearl",
        title: "Crystal Black Pearl",
      },
      {
        src:
          "https://solutions-engineering.s3.amazonaws.com/quickdemo/honda/swatches/exterior/gray-pearl.png",
        config: "Gray Pearl",
        title: "Sonic Gray Pearl",
      },
      {
        src:
          "https://solutions-engineering.s3.amazonaws.com/quickdemo/honda/swatches/exterior/lunar-silver-metallic.png",
        config: "Silver Metallic",
        title: "Lunar Silver Metallic ",
      },
      {
        src:
          "https://solutions-engineering.s3.amazonaws.com/quickdemo/honda/swatches/exterior/modern-steel-metallic.png",
        config: "Steel Metallic",
        title: "Modern Steel Metallic",
      },

      {
        src:
          "https://solutions-engineering.s3.amazonaws.com/quickdemo/honda/swatches/exterior/platinum-white-pearl.png",
        config: "White Pearl",
        title: "Platinum White Pearl",
      },
    ],
    wheels: [
      {
        src: "https://solutions-engineering.s3.amazonaws.com/quickdemo/honda/swatches/wheels/take2/alloy.png",
        config: "Alloy",
        title: "Alloy",
      },
      {
        src: "https://solutions-engineering.s3.amazonaws.com/quickdemo/honda/swatches/wheels/take2/diamond-cut.png",
        config: "Diamond",
        title: "Diamond Cut",
      },
      {
        src: "https://solutions-engineering.s3.amazonaws.com/quickdemo/honda/swatches/wheels/take2/black.png",
        config: "Black",
        title: "Black Alloy",
      },
    ],
  },
};

export default config;
