import React, { useState, useEffect } from "react";
import "./style.css";
import config from "../../config/";

import { Progress, Tabs } from "antd";
import Powertrain from "./Powertrain.js";
import Color from "./Color";
import Summary from "./Summary";
import Accessories from "./Accessories";


function Player(props) {
  const { TabPane } = Tabs;

 
  const [progress, setProgress] = useState(25);

  const handleClick = (e) => {
    setProgress(e * 25);
  };

  return (
    <div>
      <div className="form-container">
        <Progress
          percent={progress}
          strokeColor={progress != 100 ? "#007cc0" : "#52c41b"}
        />

        <div className="heading">
          <Tabs
            defaultActiveKey="1"
            centered
            onTabClick={(e) => handleClick(e)}
          >
            <TabPane tab="Powertrain" key="1">
              <Powertrain />
              {/* <Two /> */}
            </TabPane>
            <TabPane tab="Exterior Color" key="2">
              <Color />
            </TabPane>
            <TabPane tab="Wheel Style" key="3">
              <Accessories />
            </TabPane>
            <TabPane tab="Summary" key="4">
              <Summary />
            </TabPane>
          </Tabs>
        </div>
      </div>
    </div>
  );
}

export default Player;
