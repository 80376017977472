import React, { useState, useEffect } from "react";
import "./style.css";
import { apply2DSpin } from "../../helpers/spin";

function Player(props) {
  let [loaded, setLoaded] = useState(false);
  let [clicked, setClicked] = useState(false);

  const hideHand = () => {
    // document.getElementById("hand-indicator") == undefined
    //   ? window.location.reload()
    (document.getElementById("hand-indicator").style = "display: none");
  };

  useEffect(() => {
    if (clicked == false) {
      document
        .getElementById("threekit-container")
        .addEventListener("mousedown", function () {
          // document.getElementById("hand-container").remove();
          hideHand();
        });

      document
        .getElementById("threekit-container")
        .addEventListener("touchstart", function () {
          // document.getElementById("hand-container").remove();
          hideHand();
        });
    }

    window.crv = "190hp | 2wd";
    window
      .threekitPlayer({
        authToken: props.authToken,
        el: document.getElementById("player"),
        // stageId: props.stageId,
        assetId: props.assetId,
        display: "image",

        initialConfiguration: props.initialConfig,
        showConfigurator: props.showConfigurator,
        showAR: props.showAR,
      })
      .then(async (api) => {
        window.player = api;
        window.configurator = await api.getConfigurator();
        await api.when("loaded");
        await window.configurator.prefetchAttributes(["Rotation"]);

        // Set values for fake summary
        window.color = "Obsidian Blue Pearl";
        window.wheels = "Alloy";

        // Make spin
        apply2DSpin({ attrName: "Rotation", direction: 1 })(api);
        // setLoaded(true);
        // api.tools.addTool('zoom')
      });
  });

  return (
    <div>
      <div id="threekit-container">
      <div className="player-container">

        {/* {loaded ? ( */}
          <div className="stage" id="hand-container">
            <div id="hand-indicator" className="hand bounce-2">
              <img
                style={{ height: "30px", width: "30px" }}
                src="https://solutions-engineering.s3.amazonaws.com/media/web-assets/hand.png"
              />
            </div>
          </div>
        {/* ) : (
          <p></p>
        )} */}
          <div id="player"></div>
        </div>
      </div>
    </div>
  );
}

export default Player;
