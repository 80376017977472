import React, { useState, useEffect } from "react";
import "./style.css";

import { Card, Col, Row, Avatar } from "antd";
import config from "../../config";

function Accessories(props) {
  const [current, setCurrent] = useState(0);
  const [wheel, setWheel] = useState("Alloy");

  useEffect(() => {
  });
  const handleClick = (e, i) => {
    console.log(e);
    setCurrent(i);
    setWheel(e.title);
    window.wheels = e.title;
    window.configurator.setConfiguration({ "Wheels": e.config });
  };

  return (
    <div>
      <h2>Select Wheel Style</h2>
      <b>Wheel Style</b>: {wheel}
      <br />
      {config.swatches.wheels.map((e, i) => (
        <Avatar
          src={e.src}
          key={e.config}
          onClick={() => handleClick(e, i)}
          style={{ margin: 10 }}
          size={"large"}
          className={current == i ? "active" : ""}
        />
      ))}
      <br />
    </div>
  );
}

export default Accessories;
