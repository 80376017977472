import React, { useEffect } from "react";

// import "antd/dist/antd.css";

// Less
// import 'antd/lib/style/themes/default.less';
// import 'antd/dist/antd.less'; // Import Ant Design styles by less entry
import 'antd/dist/antd.less';
import './theme.less'; // variables to override above
import ReactGA from 'react-ga';


// Components
import Player from "./components/Player";
import Form from "./components/Form";

// Config
import config from "./config/index";

function App() {
  useEffect(() => {
    ReactGA.initialize("UA-63905846-9");
    ReactGA.set({ dimension1: "Auto Demo" });
    ReactGA.pageview('auto.demo.threekit.com');
  });
  return (
    <div>
      <Player
        authToken={config.authToken}
        stageId={"14b246aa-b747-4ec7-b9a9-8f81e89a673b"}
        assetId={"9ac239e7-de86-40e2-9324-5b80767a2138"}
        initialConfig={{"Exterior Color": "Blue Pearl", "Wheels": "Alloy", "Rotation": "1"}}
        showConfigurator={false}
        showAR={false}
      />
      <Form/>
    </div>
  );
}

export default App;
