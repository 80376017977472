import React, { useState, useEffect } from "react";
import "./style.css";

import { Card, Col, Row, Avatar } from "antd";
import config from "../../config";

function Color(props) {
  const [current, setCurrent] = useState(0);
  const [color, setColor] = useState("Blue Pearl");

  useEffect(() => {
  });
  const handleClick = (e, i) => {
    console.log(e);
    setCurrent(i);
    setColor(e.title);
    window.color = e.title;
    window.configurator.setConfiguration({ "Exterior Color": e.config });
  };

  return (
    <div>
      <h2>Select Exterior Color</h2>
      <b>Exterior Color</b>: {color}
      <br />
      {config.swatches.exterior.map((e, i) => (
        <Avatar
          src={e.src}
          key={e.config}
          onClick={() => handleClick(e, i)}
          style={{ margin: 10 }}
          size={"large"}
          className={current == i ? "active" : ""}
        />
      ))}
      <br />
    </div>
  );
}

export default Color;
