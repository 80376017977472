import React, { useState, useEffect } from "react";
import "./style.css";
import { Button, Table, Tabs } from "antd";
import {
  MobileView,
  BrowserView,
  AndroidView,
  IOSView,
  isIOS,
  isAndroid,
  getUA,
} from "react-device-detect";
import ReactGA from "react-ga";

const clickIos = () => {
  ReactGA.initialize("UA-63905846-9");
  ReactGA.set({ dimension1: "Auto.demo.threekit.com - AR" });
  ReactGA.pageview("Auto Demo IOS AR");
  let alink = document.getElementById("ar-ios-link").click();
};

const clickAndroid = () => {
  console.log("trying to launch Android Player");
  ReactGA.initialize("UA-63905846-9");
  ReactGA.set({ dimension1: "Auto.demo.threekit.com - AR" });
  ReactGA.pageview("Auto Demo - Android AR");
  const anchor = document.createElement("a");
  const location = window.location.toString();
  const locationUrl = new URL(location);
  const noArViewerSigil = "#no-ar-fallback";
  const modelUrl = new URL(arlink);
  const link = encodeURIComponent(location);
  const scheme = modelUrl.protocol.replace(":", "");
  locationUrl.hash = noArViewerSigil;
  const title = encodeURIComponent("title");
  modelUrl.protocol = "intent://";
  // const intent = `${modelUrl.toString()}?link=${link}&title=${title}#Intent;scheme=${scheme};package=com.google.ar.core;action=android.intent.action.VIEW;S.browser_fallback_url=${encodeURIComponent(
  //   locationUrl.toString()
  // )};end;`;
  //     const intent = `intent://arvr.google.com/scene-viewer/1.0?file=${link}#Intent;scheme=https;package=com.google.android.googlequicksearchbox;action=android.intent.action.VIEW;S.browser_fallback_url=${encodeURIComponent(
  //       locationUrl.toString()
  //     )}
  // ;end;`;

  // const intent = `intent://arvr.google.com/scene-viewer/1.0?file=${link}&mode=ar_only#Intent;scheme=https;package=com.google.ar.core;action=android.intent.action.VIEW;S.browser_fallback_url=https://developers.google.com/ar;end;`;
  const intent = `intent://arvr.google.com/scene-viewer/1.0?&file=${arlink}&mode=ar_only#Intent;scheme=https;package=com.google.ar.core;action=android.intent.action.VIEW;S.browser_fallback_url=${encodeURIComponent(
    locationUrl.toString()
  )};end;`;

  anchor.setAttribute("href", intent);
  anchor.click();
};

let arlink = isIOS
  ? "https://solutions-engineering.s3.amazonaws.com/quickdemo/honda/AR/ARHondaCRVUnwrapped.usdz"
  : isAndroid
  ? "https://solutions-engineering.s3.amazonaws.com/quickdemo/honda/AR/ARHondaCRVUnwrapped.glb"
  : null;

var wxyz,
  standalone = window.navigator.standalone,
  userAgent = window.navigator.userAgent.toLowerCase(),
  safari = /safari/.test(userAgent),
  ios = /iphone|ipod|ipad/.test(userAgent);
function Summary(props) {
  useEffect(() => {});

  return (
    <div>
      <h2>Summary</h2>
      {window.color == "Obsidian Blue Pearl" && window.wheels == "Alloy" ? (
        <MobileView>
          <IOSView>
            {getUA.includes("[LinkedInApp]") ? (
              <Button disabled={true}>
                To view in AR open in mobile browser
              </Button>
            ) : (
              <Button onClick={clickIos} className="ar-btn">
                View in your space
              </Button>
            )}
            <br />

            <div style={{ display: "none" }}>
              <a href={arlink} rel="ar" id="ar-ios-link">
                <img
                  style={{ display: "none" }}
                  src="https://venturebeat.com/wp-content/uploads/2019/11/threekit-logo.png?fit=400%2C200&strip=all"
                  alt="hidden iOs ar link"
                />
              </a>
            </div>
          </IOSView>
          <AndroidView>
            <Button onClick={clickAndroid} className="ar-btn">
              View in AR
            </Button>
            <br />
          </AndroidView>
        </MobileView>
      ) : (
        <p></p>
      )}
      <p>
        <b>Selected Powertrain</b>: {window.crv}
      </p>
      <p>
        <b>Selected Exterior Color</b>: {window.color}
      </p>
      <p>
        <b>Selected Wheel Style</b>: {window.wheels}
      </p>
    </div>
  );
}

export default Summary;
