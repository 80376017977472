import React, { useState, useEffect } from "react";
import "./style.css";

import { Card, Col, Row } from "antd";

function Powertrain(props) {
  const [current, setCurrent] = useState(0);

  const handleClick = (i) => {
    console.log(i);
    setCurrent(i);
    if (i == 0) {
      window.crv = "190hp | 2wd";
    } else if (i == 1) {
      window.crv = "190hp | AWD";
    }
  };
  return (
    <div>
      <h2>Select Powertrain</h2>
      <div className="card-container">
        <Row gutter={16}>
          <Col >
            <img
              alt="2wd"
              src="https://solutions-engineering.s3.amazonaws.com/quickdemo/honda/swatches/powertrain/2wd.png"
              className={current == 0 ? "active" : ""}
              onClick={() => handleClick(0)}
            />
          </Col>
          <Col >
          <img
              alt="4wd"
              src="https://solutions-engineering.s3.amazonaws.com/quickdemo/honda/swatches/powertrain/awd.png"
              className={current == 1 ? "active" : ""}
              onClick={() => handleClick(1)}
            />
          </Col>
        </Row>
      </div>
    </div>
  );
}

export default Powertrain;
